export default {
  debug: false,
  exposeDesignSystem: 'prod' === 'dev',
  build: '33795',
  api: {
    url: 'https://case-api.uniforlsp.com/api',
    audience: 'api://ed3169a1-889a-459d-b4c9-39f5bfdcd6fe',
  },
  msal: {
    auth: {
      clientId: 'ed3169a1-889a-459d-b4c9-39f5bfdcd6fe',
      authority: 'https://login.microsoftonline.com/02b6e3d2-b34e-4da7-b8fb-2cf048c0e588',
      redirectUri: window.location.origin,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  },
  sentry: {
    enabled: 'true' === 'true',
    options: {
      dsn: 'https://1d32d0f3a4324f0a8e2e698f8185e066@o1294252.ingest.sentry.io/6520188',
      tracesSampleRate: parseFloat('1.0'),
    },
  },
};
